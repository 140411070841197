var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('file-pond',{ref:"file",attrs:{"name":"file","allowRevert":"false","files":_vm.files,"label-idle":"Arraste aqui ou escolher arquivo","server":{
    url:
      _vm.url +
      'favorecidos/importar',
    process: {
      headers: {
        'x-auth-token': this.token,
      },
      onload: (response) => {
        this.response = JSON.parse(response);

        if (this.response.status && this.response.status == 'success') {
          this.$router.push('/favorecidos/importar/' + this.response.file.id);
        } else if (this.response.status && this.response.status == 'error') {
          this.$toast.error(this.response.message);
        } else {
          this.$parent.$parent.getFiles();
        }
      }
    },
  }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }